/**
 * Format raw size to return human readable one
 *
 * @param {Number} size
 * @param {string} byteUnit
 * @param {Number} divider Change this to 1000 if you want to use SI unit.
 * @return {String} human readable size
 */
export const humanReadableSize = (
  size: number,
  byteUnit: string,
  divider = 1024,
) => {
  let bytes = size

  if (Math.abs(bytes) < divider) {
    return `${bytes}${byteUnit}`
  }

  const units = [
    `k${byteUnit}`,
    `M${byteUnit}`,
    `G${byteUnit}`,
    `T${byteUnit}`,
    `P${byteUnit}`,
    `E${byteUnit}`,
    `Z${byteUnit}`,
    `Y${byteUnit}`,
  ]

  let unit = -1
  do {
    bytes /= divider
    unit += 1
  } while (Math.abs(bytes) >= divider && unit < units.length - 1)

  return `${bytes.toFixed(2)}${units[unit]}`
}
